<template>
  <b-list-group-item
    class="p-0 d-flex align-items-stretch justify-content-between"
  >
    <!------------ START: Item text ------------>
    <div
      class="btn btn-clean rounded-0 text-left flex-grow-1 px-3 py-2 d-flex flex-nowrap align-items-center"
      @click="select"
    >
      <i
        v-if="showValue"
        ref="value"
        class="fal fa-circle-info align-middle mr-1"
      />
      <span class="text-break">{{ String(text) }}</span>
    </div>
    <!------------ END: Item text ------------>
    <!------------ START: Item show children ------------>
    <div
      v-if="hasChildren"
      class="btn btn-clean rounded-0 px-3 py-2 border-left d-flex align-items-center justify-content-center"
      @click="openDetails"
    >
      <i class="fal fa-angle-right align-middle" />
    </div>
    <!------------ END: Item show children ------------>
    <b-tooltip
      v-if="showValue"
      :target="() => $refs.value"
      placement="top"
      noninteractive
      custom-class="form-helper-tooltip form-helper-variables-tooltip"
    >
      <div class="font-weight-bold">{{ $t("formHelper.value") }}</div>
      <pre class="text-left mb-0 text-wrap">{{ tooltipValue }}</pre>
    </b-tooltip>
  </b-list-group-item>
</template>

<script>
export default {
  components: {},
  props: {
    set: {
      type: Object,
      default: () => {}
    },
    text: {
      type: null,
      default: ""
    },
    value: {
      type: null,
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {
    // Return whether variable's value is an object
    hasChildren: function () {
      return typeof this.value === "object";
    },
    showValue: function () {
      return this.set.name === "configValues" || !!this.set.showValue;
    },
    tooltipValue: function () {
      let value = JSON.stringify(this.value);
      return value.length > 100 ? value.slice(0, 100) + "..." : value;
    }
  },
  mounted() {},
  methods: {
    // Emit select event
    select() {
      this.$emit("select", this.text);
    },
    // Emit event to add carousel item with variable's object value as items
    openDetails() {
      const payload = {
        set: this.set,
        text: this.text,
        value: this.value
      };
      this.$emit("open", payload);
    }
  }
};
</script>
