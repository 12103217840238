<template>
  <div>
    <div class="font-weight-bold mb-2">
      {{ $t("variables." + set.name) }}
    </div>
    <b-list-group>
      <SetItem
        v-for="(variable, i) in variablesFiltered"
        :key="i"
        :set="set"
        :text="variable.text"
        :value="variable.value"
        @open="open"
        @select="select"
      />
      <div v-if="!variablesFiltered.length" class="text-muted">
        {{
          filter.length
            ? $t("formHelper.noMatchingVariables")
            : $t("formHelper.noVariables")
        }}
      </div>
    </b-list-group>
  </div>
</template>

<script>
import SetItem from "@/components/Tools/FormHelper/Components/VariablesDropdown/SetItem";

export default {
  components: { SetItem },
  inject: ["options"],
  props: {
    set: {
      type: Object,
      default: () => {}
    },
    filter: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      level: 0
    };
  },
  computed: {
    computedFilter() {
      let filter = this.filter.split(".");
      if (filter[0] === this.set.prefix) {
        filter.shift();
      }
      return filter.join(".").trim().toLowerCase();
    },
    variablesFiltered: function () {
      // Filter
      let variables = this.set.variables;
      if (this.options.distinctVariables) {
        variables = this.set.variables.filter(
          (obj, index) =>
            this.set.variables.findIndex(item => item.text === obj.text) ===
            index
        );
      }

      // If no filter given, return all items with text
      if (!this.filter) {
        return variables.filter(v => v.text !== undefined);
      }

      let variablesFlat = this.set.variablesFlat ? this.set.variablesFlat : [];

      let flattenFiltered = variablesFlat.filter(
        v => v && v.toLowerCase().includes(this.computedFilter)
      );

      // Else filter items
      return variables.filter(variable =>
        flattenFiltered.find(
          v =>
            variable.text &&
            v.split(".")[this.level]?.toLowerCase() ===
              variable.text.toLowerCase()
        )
      );
    }
  },
  mounted() {},
  methods: {
    open(payload) {
      this.$emit("open", payload);
    },
    select(payload) {
      let name = [payload];
      if (this.set.prefix) {
        name.unshift(this.set.prefix);
      }
      payload = name.join(".");
      this.$emit("select", payload);
    }
  }
};
</script>
