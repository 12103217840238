<template>
  <div>
    <div class="mb-2">
      <span class="btn btn-sm btn-clean btn-icon mr-2" @click="back">
        <i class="fal fa-angle-left" />
      </span>
      <span class="font-weight-bold">
        {{ item.name }}
      </span>
    </div>
    <b-list-group>
      <SetItem
        v-for="(value, key) in variablesFiltered"
        :key="key"
        :set="item.set"
        :text="key"
        :value="value"
        @open="open"
        @select="select"
      />
    </b-list-group>
  </div>
</template>

<script>
import SetItem from "@/components/Tools/FormHelper/Components/VariablesDropdown/SetItem";

export default {
  components: { SetItem },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    filter: {
      type: String,
      default: ""
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {};
  },
  computed: {
    computedFilter() {
      let filter = this.filter.split(".");
      if (filter[0] === this.item.set.prefix) {
        filter.shift();
      }
      return filter.join(".").trim().toLowerCase();
    },
    variablesFiltered: function () {
      // If no filter given, return all items
      if (!this.filter) {
        return this.item.variables;
      }
      let flattenFiltered = this.item.set.variablesFlat.filter(v =>
        v.toLowerCase().includes(this.computedFilter)
      );
      // Else filter items
      let variables = {};
      let keys = Object.keys(this.item.variables).filter(key =>
        flattenFiltered.find(
          v => v.split(".")[this.level]?.toLowerCase() === key.toLowerCase()
        )
      );
      keys.forEach(key => (variables[key] = this.item.variables[key]));
      return variables;
    }
  },
  mounted() {},
  methods: {
    open(payload) {
      payload.text = [this.item.name, payload.text].join(".");
      this.$emit("open", payload);
    },
    select(payload) {
      let name = [this.item.name, payload];
      if (this.item.set.prefix) {
        name.unshift(this.item.set.prefix);
      }
      payload = name.join(".");
      this.$emit("select", payload);
    },
    back() {
      this.$emit("back");
    }
  }
};
</script>
