<template>
  <div
    v-if="show"
    ref="variablesDropdown"
    class="position-fixed zindex-10 bg-white border border-top-0 py-3 shadow rounded-lg rounded-t-0"
    :style="`left: ${target.x}px; top: ${target.y}px; width: ${target.width}px;`"
  >
    <perfect-scrollbar
      class="scroll h-100 px-3"
      style="max-height: 250px"
      :options="scrollbarOptions"
    >
      <!------------ START: Carousel ------------>
      <v-carousel
        v-model="carousel"
        :show-arrows="false"
        hide-delimiters
        height="auto"
      >
        <v-carousel-item>
          <Set
            v-for="(set, i) in sets"
            :key="i"
            :class="{ 'mt-2': i !== 0 }"
            :set="set"
            :filter="filterCleared"
            @open="addItem"
            @select="select"
          />
        </v-carousel-item>
        <v-carousel-item v-for="(item, i) in carouselItems" :key="i">
          <SetNested
            :item="item"
            :filter="filterCleared"
            :level="i + 1"
            @open="addItem"
            @select="select"
            @back="removeItem"
          />
        </v-carousel-item>
      </v-carousel>
      <!------------ END: Carousel ------------>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Set from "@/components/Tools/FormHelper/Components/VariablesDropdown/Set";
import SetNested from "@/components/Tools/FormHelper/Components/VariablesDropdown/SetNested";

export default {
  components: { SetNested, Set },
  inject: ["options"],
  model: {
    prop: "show"
  },
  props: {
    // Injected per v-model
    show: {
      type: Boolean
    },
    // Input string to filter variables
    filter: {
      type: String,
      default: ""
    },
    // Element which dropdown should be attached to
    el: {
      type: HTMLElement,
      default: () => {}
    },
    // If input differs from attached element
    inputEl: {
      type: null,
      default: () => {}
    },
    useCurlyBrackets: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      carousel: 0,
      carouselItems: [],
      currentFullName: "",
      target: {
        x: 0,
        y: 0,
        width: 0
      },
      scrollbarOptions: {
        wheelPropagation: false
      }
    };
  },
  computed: {
    ...mapGetters("variables", [
      "configValuesNormalized",
      "configValuesFlat",
      "presetValuesNormalized",
      "presetValuesFlat",
      "customVariablesSet"
    ]),
    sets: function () {
      let sets = [...this.customSets];
      if (this.options.configValues) {
        sets.push(this.configValuesSet);
      }
      if (this.options.presetValues) {
        sets.push(this.presetValuesSet);
      }

      return sets;
    },
    customSets: function () {
      let sets = [];
      (this.options.customVariables ?? []).forEach(name => {
        sets.push(this.customVariablesSet(name));
      });
      return sets;
    },
    configValuesSet: function () {
      return {
        name: "configValues",
        prefix: "config",
        variables: this.configValuesNormalized,
        variablesFlat: this.configValuesFlat
      };
    },
    presetValuesSet: function () {
      return {
        name: "presetValues",
        prefix: "preset",
        variables: this.presetValuesNormalized,
        variablesFlat: this.presetValuesFlat
      };
    },
    prefixes: function () {
      let prefixes = [];
      if (this.options.configValues) {
        prefixes.push("config");
      }
      this.customSets.forEach(config => prefixes.push(config.prefix));
      return prefixes;
    },
    filterCleared: function () {
      if (!this.filter) {
        return "";
      }
      // Remove all curly brackets and spaces
      return this.filter.replaceAll(/[{}\s]/gm, "");
    }
  },
  watch: {
    // Remove all carousel items to show root item when opened again
    show: function () {
      if (this.show === false) {
        this.onClose();
      } else {
        this.onOpen();
      }
    }
  },
  created() {
    window.addEventListener("click", this.focusChanged);
    window.addEventListener("scroll", this.onScroll);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("click", this.focusChanged);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    // Add carousel item
    addItem(payload) {
      // Add item with variables name as title and its value as variables
      let item = {
        set: payload.set,
        name: payload.text,
        variables: payload.value.value ?? payload.value
      };
      this.carouselItems.push(item);
      setTimeout(() => this.carousel++, 100);
    },
    // Remove last carousel item
    removeItem() {
      this.carousel--;
      setTimeout(() => this.carouselItems.pop(), 300);
    },
    select(payload) {
      let variable = payload;

      if (this.useCurlyBrackets) {
        variable = `{{${variable}}}`;
      }

      this.$emit("select", variable);
    },
    focusChanged(e) {
      // Check if dropdown is already hidden
      if (!this.show) {
        return;
      }
      // Check if click was on dropdown
      let hasFocus = this.$refs.variablesDropdown.contains(e.target);
      // Check if click was on input
      let inputFocus = (this.inputEl ?? this.el).contains(e.target);
      // If none of them above was clicked, hide dropdown
      if (!hasFocus && !inputFocus) {
        this.$emit("input", false);
      } else {
        if (hasFocus) {
          (this.inputEl ?? this.el).focus();
        }
        // Else update position
        this.setTarget();
      }
    },
    // On open check if a nested variable is selected and show the correct nested item
    onOpen() {
      // Update position
      this.setTarget();
      // Get current value and its prefix
      let value = this.filterCleared.split(".");
      let prefix = "";
      // If first part is prefix, remove
      if (this.prefixes.includes(value[0]) && value.length > 1) {
        prefix = value.shift();
      }
      // Get active variable set by prefix
      let activeSet =
        prefix === "config"
          ? [this.configValuesSet]
          : prefix
          ? this.customSets.filter(set => set.prefix === prefix)
          : // If first part of value is not prefix, filter sets by variable texts
            this.customSets.filter(set =>
              set.variables.some(v => v.text === value[0])
            );
      // If no set was found or value has no prefix and multiple sets were found, return
      if (activeSet.length !== 1) {
        return;
      }
      activeSet = activeSet[0];
      // Get set's root variable name
      let rootItemName = value.shift();
      // Get variable by name
      let item = activeSet.variables.find(
        variable => variable[activeSet.text ?? "text"] === rootItemName
      );
      // Set variables name and value
      let name = rootItemName;
      let valueObject = item[activeSet.value ?? "value"];
      let fullName = rootItemName;

      // While variable has still parts
      while (value.length) {
        // Define correct payload for carousel item addition
        const payload = {
          set: activeSet,
          text: fullName,
          value: valueObject
        };
        // Add new carousel item
        this.addItem(payload);
        // Get next variable part
        name = value.shift();
        fullName += "." + name;
        // If next variable's value is not of type object or does not exist, return
        if (
          typeof valueObject[name] !== "object" ||
          valueObject[name] === undefined
        ) {
          break;
        }
        // Continue with current variable's value
        valueObject = valueObject[name];
      }
    },
    onClose() {
      this.carousel = 0;
      this.carouselItems = [];
    },
    setTarget() {
      let rect = this.el.getBoundingClientRect();
      this.target = {
        x: rect.x,
        y: rect.y + rect.height,
        width: rect.width > 250 ? rect.width : 250
      };
    },
    onScroll() {
      // If dropdown is shown
      if (this.show) {
        // Update position
        this.setTarget();
      }
    }
  }
};
</script>

<style lang="scss">
.zindex-10 {
  z-index: 10;
}
</style>
